<template>
    <div class="box">
        <div class="search">
            <div class="search-top">
                <div v-for="(item,i) in toplist" :key="i">{{item.name}}（{{item.value}}）</div>
            </div>
            <el-form label-width="120px" label-height="60px">
                <el-row>
                  <el-col :span="6">
                    <el-form-item label="店铺ID：" prop="id">
                      <el-input
                        v-model="queryInfo.condition.id"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="店铺名称：" prop="businessName">
                      <el-input
                        v-model="queryInfo.condition.businessName"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="申请时间：" prop="applicantTime">
                      <el-input
                        v-model="queryInfo.condition.applicantTime"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="店铺分类：" prop="industryType">
                      <el-select v-model="queryInfo.condition.industryType" placeholder="请选择">
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6">
                    <el-form-item label="联系电话：" prop="linkPhone">
                      <el-input
                        v-model="queryInfo.condition.linkPhone"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="经营范围：" prop="scope">
                      <el-input
                        v-model="queryInfo.condition.scope"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6" style="display: flex; justify-content: flex-end">
                    <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
                    <el-button type="primary" @click="search" icon="el-icon-search"
                      >搜索</el-button
                    >
                </el-col>
                  <!-- <el-col :span="8">
                    <el-form-item label="行业分类：" prop="industryType">
                      <el-input
                        v-model="queryInfo.condition.industryType"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col> -->
                </el-row>
              <!-- <el-row>
                <el-col :span="8">
                    <el-form-item label="地区：" prop="industryType">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="商家状态：" prop="businessStatus">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                </el-col>
               
                <el-col :span="8" style="display: flex; justify-content: flex-end">
                    <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
                    <el-button type="primary" @click="search" icon="el-icon-search"
                      >搜索</el-button
                    >
                </el-col>
               
              </el-row> -->
            </el-form>
        </div>
        <div class="tab">
              <div class="add">
                  <el-button
                    type="primary"
                    @click="added"
                    icon="el-icon-circle-plus-outline"
                    >新增</el-button
                  >
                  <el-button
                    type="primary"
                    onclick="document.getElementById('file-input').click();"
                    icon="el-icon-circle-plus-outline"
                    >导入</el-button
                  >
                  <input id="file-input" type="file" @change="importClick" style="display: none;">
              </div>
            <el-table
        :data="tableData"
        style="width: 100%"
        height="calc(100vh - 430px)"
        border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }"
      >
      <el-table-column
          prop="id"
          label="店铺ID"
          width="170"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="logoUrl"
          label="店铺头像"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <img style="height: 50px;width: 50px;" :src="scope.row.logoUrl" alt="">
          </template>
        </el-table-column>
        <el-table-column
          prop="businessName"
          label="店铺名称"
          show-overflow-tooltip
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="headPhone"
          label="手机号"
          width="100"
          align="center"
        >
  
        </el-table-column>
        <el-table-column
          prop="groupNum"
          label="团购商品数"
          width="100"
          align="center"
        >
        <template slot-scope="scope">
        <el-button type="text"   @click="gosp(scope.row)" >{{scope.row.groupNum}}</el-button>
      </template>
        </el-table-column>
        <el-table-column
          prop="deliveryNum"
          label="外卖商品数"
          width="100"
          align="center"
        >
        <template slot-scope="scope">
        <el-button type="text"  @click="gosp(scope.row)" >{{scope.row.deliveryNum}}</el-button>
      </template>
        </el-table-column>
        <!-- <el-table-column
          prop="allowPayoutsNum"
          label="店铺编码"
          width="100"
          align="center"
        >
        </el-table-column> -->
       
        <el-table-column
          prop="address"
          label="店铺地址"
          show-overflow-tooltip
         width="250"
          align="center"
        >
        <template slot-scope="scope">
        {{scope.row.provinceName+scope.row.cityName+scope.row.areaName+scope.row.address}}
      </template>
  
        </el-table-column>

        <el-table-column
          prop="scope"
          label="业务管理"
          width="150"
          align="center"
        >
  
        </el-table-column>
        <!-- <el-table-column
          prop="deliveryMethod"
          label="配送方式"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.deliveryMethod==1">商家自配送</span>
            <span v-if="scope.row.deliveryMethod==2">平台配送</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="industryType"
          label="店铺分类"
         
          align="center"
        >
  
        </el-table-column>
        <el-table-column
          prop="businessStartTime"
          label="营业时间"
          width="150"
          align="center"
        >
        <template slot-scope="scope">
            <span>{{scope.row.businessStartTime}}</span>- <span>{{scope.row.businessEndTime}}</span>
            
           
            
          </template>
        </el-table-column>
        
        <el-table-column
          prop="businessStatus"
          label="商家状态"
         
          align="center"
        >
        <template slot-scope="scope">
            <span v-if="scope.row.businessStatus==1">自动营业</span>
            <span v-if="scope.row.businessStatus==2">自动打烊</span>
            <span v-if="scope.row.businessStatus==3">手动营业</span>
            <span v-if="scope.row.businessStatus==4">手动打烊</span>
            
          </template>
        </el-table-column>
        <el-table-column
          prop="applicantTime"
          label="申请时间"
          width="150"
          align="center"
        >
  
        </el-table-column>
        <el-table-column
          prop="auditStatus"
          label="审核状态"
         
          align="center"
        >
        <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.auditStatus==1">审核中</el-button>
            <el-button type="text" v-else-if="scope.row.auditStatus==2">审核拒绝</el-button>
            <el-button type="text" v-else>审核通过</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop=" "
          label="操作"
          width="200"
          fixed="right"
          align="center"
        >
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row.id)" >编辑</el-button>
            <el-button type="text" style="color: red;"  @click="sh(scope.row.id)" >审核</el-button>
            
          </template>
  
  
        </el-table-column>
  
  
            </el-table>  
            <div class="pagination">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="queryInfo.currPage"
                :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
        </div> 
        <el-dialog
          title="店铺审核详情"
          :visible.sync="showDialogtwo"
          width="1000px"
          height="1000px"
        >
          <div class="diatwo">
            <div class="dialist">
                <div class="dia-li">
                    <div class="name">店铺头像</div>
                    <img :src="businessdetail.logoUrl " alt="">
                </div>
                <div class="dia-li">
                    <div class="name">店铺名称</div>
                    <div>{{businessdetail.businessName }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">店铺账号</div>
                    <div>{{businessdetail.applicantPhone }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">店铺地区</div>
                    <div>{{businessdetail.provinceName }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">业务管理</div>
                    <div>{{businessdetail.scope }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">行业分类</div>
                    <div>{{businessdetail.storeClassification }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">营业时间</div>
                    <div>{{businessdetail.businessStartTime }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">法定代表人</div>
                    <div>{{businessdetail.userName }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">联系电话</div>
                    <div>{{ businessdetail.linkPhone }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">连锁编码</div>
                    <div>{{ businessdetail.linkPhone }}</div>
                </div>
                
            </div>
            <div class="diaimg">
                <div class="imgbox">
                    <div class="name">身份证扫描件</div>
                    <div class="imgli">
                        <img :src="businessdetail.idCardImgFront" alt="">
                        <img :src="businessdetail.idCardImgReverse" alt="">
                    </div>
                </div>
                <div class="imgbox">
                    <div class="name">营业执照</div>
                    <div class="imgli">
                        <img :src="businessdetail.logoUrl" alt="">
                    </div>
                </div>
                <div class="imgbox">
                    <div class="name">资质许可证</div>
                    <div class="imgli">
                        <img :src="businessdetail.qualifications" alt="">
                    </div>
                </div>
                <div class="imgbox">
                    <div class="name">经营场景</div>
                    <div class="imgli">
                        <img :src="businessdetail.qualifications" alt="">
                    </div>
                </div>
                <div class="imgbox">
                    <div class="name">荣誉证书</div>
                    <div class="imgli">
                        <img :src="businessdetail.qualifications" alt="">
                    </div>
                </div>
            </div>
            <div class="diaimg">
                <div class="imgbox">
                    <div class="name">店铺环境照</div>
                    <div class="imgli">
                        <img :src="businessdetail.environmentImg" alt="">
                        <img :src="businessdetail.headImg " alt="">
                        <img :src="businessdetail.lobbyImg" alt="">
                    </div>
                </div>
                
            </div>
            <div style="margin-top: 40px;">是否同意该商家店铺入驻小尼甄选平台？</div>
            <div class="sh">
                <div >审核备注 :</div>
                <textarea v-model="shyj" name="" id=""></textarea>
            </div>
            <div class="but">
                <el-button type="danger" @click="nook()">不同意 </el-button>
                <el-button type="primary" @click="ok()">同意 </el-button>
  
            </div>
            
          </div>
        </el-dialog> 
    </div>
    
  </template>
  
  <script>
  export default {
  data() {
    return {
        options: [{
          value: '0',
          label: '全部为空'
        }, {
          value: '1',
          label: '外卖为空'
        }, {
          value: '2',
          label: '团购为空'
        }],
        showDialogtwo:false,
        activeName: 'second',
        drawer:false,
        toplist:[{
            name:'待审核',
            value:''
        }],
        tableData:[],
        queryInfo:{
          condition: {
            isChain:0,
            id:'',
            businessName:'',
            applicantTime:'',
            linkPhone:'',
            scope:'',
            industryType:'',
            businessStatus:''
          },
          currPage: 1,
          pageCount: 1,
          pageSize: 10
         
        },
        total :0,
        showDialog:false,
        businessdetail:{},
        id:'',
        value1:0,
        shyj:'',
        detaillist:{
          integralRatioUse:'',
          integralRatioGet:'',
          takeawayRake:'',
          buyRake:'',
          yardRake:'',
          accountTerm:'',
          integralDeduceAuthority:'',
          platformDeliveryAuthority:'',
          couponVerificateAuthority:'',
          selfDeliveryAuthority:''
        },
        txdetail:[],
        pjdetail:[],
        htdetail:[]
    };
  },
  created() {
    this.$set(this.queryInfo,'currPage',this.$route.query.pageNum || 1)
  },
  mounted() {
    this.getList ()
  },
  methods: {
    gosp(row){
      this.$router.push({
          path:'/commodity',query:{
            name:row.businessName
          }})
    },
    change(e){
      console.log(e);
    },
    added(){
        this.$router.push({path:'/staffDetail'})
    },
    //获取数据
    getList () {
      var that = this;
      that.$http.post("/business/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    search(){
      this.queryInfo.currPage=1;
      this.getList()
    },
    reset(){
      this.queryInfo.condition = {
        id:'',
        businessName:'',
        applicantTime:'',
        linkPhone:'',
        scope:'',
        industryType:'',
        businessStatus:''
      }
      this.queryInfo.currPage=1;
      this.getList()
    },
    sh(id){
      this.showDialogtwo = true
      this.id = id
      var that = this;
      
      that.$http.post("/business/queryBusinessDetail",{
        id:id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.businessdetail = response.data.data
        }
      });
      
    },
    deta(id){
      this.drawer=true
     
      this.id = id
      var that = this;
      
      that.$http.post("/business/queryBusinessDetail",{
        id:id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.businessdetail = response.data.data
          that.detaillist = response.data.data
          if(that.detaillist.integralDeduceAuthority == 0){
            that.detaillist.integralDeduceAuthority = false
      } else {
        that.detaillist.integralDeduceAuthority = true
      }
      if(that.detaillist.platformDeliveryAuthority == 0){
        that.detaillist.platformDeliveryAuthority = false
      } else {
        that.detaillist.platformDeliveryAuthority = true
      }
      if(that.detaillist.couponVerificateAuthority == 0){
        that.detaillist.couponVerificateAuthority = false
      } else {
        that.detaillist.couponVerificateAuthority = true
      }
      if(that.detaillist.selfDeliveryAuthority == 0){
        that.detaillist.selfDeliveryAuthority = false
      } else {
        that.detaillist.selfDeliveryAuthority = true
      }
          // that.detaillist.integralRatioUse = that.businessdetail.integralRatioUse
          // that.detaillist.integralRatioGet = that.businessdetail.integralRatioGet
          // that.detaillist.takeawayRake = that.businessdetail.takeawayRake
          // that.detaillist.buyRake = that.businessdetail.buyRake
          // that.detaillist.yardRake = that.businessdetail.yardRake 
          // that.detaillist.accountTerm = that.businessdetail.accountTerm
          // that.detaillist.integralDeduceAuthority = that.businessdetail.integralDeduceAuthority
          // that.detaillist.platformDeliveryAuthority = that.businessdetail.platformDeliveryAuthority
          // that.detaillist.couponVerificateAuthority = that.businessdetail.couponVerificateAuthority
          // that.detaillist.selfDeliveryAuthority = that.businessdetail.selfDeliveryAuthority
        }
      });
      //提现明细
      that.$http.post("/businessTransaction/list",{
        condition: {
            id:id,
          },
          currPage: 1,
          pageCount: 1,
          pageSize: 10
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.txdetail = response.data.data
        }
      });
      //评价
      that.$http.post("/businessAppraise/list",{
        condition: {
            id:id,
          },
          currPage: 1,
          pageCount: 1,
          pageSize: 10
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.pjdetail = response.data.data
        }
      });
      //合同
      that.$http.post("/businessContract/queryList",{
        condition: {
            id:id,
          },
          currPage: 1,
          pageCount: 1,
          pageSize: 10
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.htdetail = response.data.data
        }
      });
      
    },
    //审核
    ok(){
      var that = this;
      that.$http.post("/business/auditBusiness", {
        id:that.id,
        auditRemark:this.shyj,
        auditStatus:3
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.showDialogtwo = false
          that.getList()
          console.log(response.data.data);
        }
        
      });
    },
    nook(){
      var that = this;
      that.$http.post("/business/auditBusiness", {
        id:that.id,
        auditRemark:this.shyj,
        auditStatus:2
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
            that.showDialogtwo = false
            that.getList()
        }
      });
    },
    detailok(){
      var that = this;
      if(this.detaillist.integralDeduceAuthority == true){
        this.detaillist.integralDeduceAuthority = 1
      } else {
        this.detaillist.integralDeduceAuthority = 0
      }
      if(this.detaillist.platformDeliveryAuthority == true){
        this.detaillist.platformDeliveryAuthority = 1
      } else {
        this.detaillist.platformDeliveryAuthority = 0
      }
      if(this.detaillist.couponVerificateAuthority == true){
        this.detaillist.couponVerificateAuthority = 1
      } else {
        this.detaillist.couponVerificateAuthority = 0
      }
      if(this.detaillist.selfDeliveryAuthority == true){
        this.detaillist.selfDeliveryAuthority = 1
      } else {
        this.detaillist.selfDeliveryAuthority = 0
      }
      const detaillist = that.detaillist
      that.$http.post("/business/saveBusiness",
        detaillist
      ).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.drawer = false
          that.getList()
          this.$message.success("保存成功");
          
        }
        
      });
    },
    added(){
        this.$router.push({path:'/auditdetailtwo'})
    },
    edit(id){
      this.$router.push({
          path:'/auditdetailtwo',query:{
        id:id,
        pageNum:this.queryInfo.currPage
      }})
    },
      // 修改页数大小
      handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
    async importClick(event){
          console.log('导入',event)
            const file = event.target.files[0];
            // 使用 FormData 包装文件
            const formData = new FormData();
            formData.append('file', file);
            const res = await this.$http.post("caseParameter/importCaseParameter", formData, {
              headers: {
                  'Content-Type': 'multipart/form-data', // 设置正确的Content-Type
              },
            });
            if(res.data.code === 200){
                this.getList()
                that.$notify.success({
                  title: "提示",
                  message: "导入成功",
                  showClose: true,
                });
            }else{
                this.$notify.info({
                  title: "提示",
                  message: res.data.message,
                  showClose: true,
                });
            }
        }
   
  }
  };
  </script>
  
  <style lang="less" scoped>
  .box {
    .search {
        background-color: #fff;
        height: 160px;
        box-sizing: border-box;
        padding-top: 20px;
        padding-left: 20px;
        .search-top {
            width: 1540px;
            height: 33px;
            padding: 2px 2px 2px 2px;
            background-color: rgba(30, 152, 215, 0.14);
           
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            div {
                width: 150px;
                text-align: center;
            }
        }
    }
    .tab {
        background-color: #fff;
        margin-top: 20px;
        padding: 20px
    }
    .dia {
      .diafour {
            display: flex;
            justify-content: space-between;
            padding: 20px 130px;
        }
    }
    .drawer {
        height: 900px;
        overflow-y: scroll;
        .drauser {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0 30px;
            box-sizing: border-box;
            div {
                width: 30%;
                color: #847575;
                margin-bottom: 30px;
                span {
                    color: #000;
                }
            }
        }
        .draimg {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0 30px;
            .imgbox {
                width: 30%;
                display: flex;
               
                .imgname {
                    color: #000;
                }
                .img {
                    margin-top: 40px;
                    display: flex;
                    img {
                        width: 89px;
                        height: 65px;
                    }
                }
  
            }
        }
        .bl {
            padding: 0 30px;
            .bl-li {
                display: flex;
                align-items: center;
                margin-top: 15px;
                .bls {
                    display: flex;
                    align-items: center;
                    margin-left: 15px;
                    input {
                        width: 100px;
                        height: 32px;
                        text-align: center
                    }
                    .text-box {
                        width: 38px;
                        height: 38px;
                        background-color: rgba(230, 230, 230, 0.55);
                        text-align: center;
                        line-height: 38px;
                    }
                }
            }
        }
        .qx {
            padding: 0 30px;
            margin-top: 30px;
        }
    }
    .diatwo {
        box-sizing: border-box;
        padding: 0 30px;
        .dialist {
            display: flex;
            flex-wrap: wrap;
            .dia-li {
                width: 250px;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .name {
                    font-weight: 700;
                    margin-right: 20px;
  
                }
                img {
                    width: 48px;
                    height: 47px;
                }
            }
        }
        .diaimg {
            display: flex;
            flex-wrap: wrap;
            .imgbox {
                width: 300px;
                margin-bottom: 15px;
                .name {
                    font-weight: 700;
  
                }
                .imgli {
                    display: flex;
                    margin-top: 15px;
                    img {
                        width: 92px;
                        height: 65px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .sh {
            display: flex;
            margin-top: 40px;
            textarea {
                width: 679px;
                height: 60px;
                margin-left: 10px;
            }
            
        }
        .but {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        padding: 0 300px;
    }
    }
  }
  </style>
  